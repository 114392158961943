/**
 * useAppSdk
 * @return the appSdk instance after initialization
 */
import UiLocation from "@contentstack/app-sdk/dist/src/uiLocation";
import { MarketplaceAppContext } from "../contexts/marketplaceContext";
import { useContext } from "react";

/**
 * Getter and setter for appSdk instance.
 * To be used during Sdk initialisation
 * @returns appSdk;
 *
 * Eg:
 * const appSdk = useAppSdk();
 */
export const useAppSdk = (): UiLocation | null => {
  const { appSdk } = useContext(MarketplaceAppContext);
  return appSdk;
};
